<template>
  <div>
    <h1>(BETA) Evidencing Group Validation</h1>

    <div class="file-viewer">
      <div class="file" v-for="fileDetail in fileEmbeds">
        <div v-if="fileDetail != null">
          <p>{{ fileDetail['filename'] }}</p>
          <embed v-if="fileDetail['fileExt'] == 'pdf'" :src="fileDetail['src']">
          <img v-else :src="fileDetail['src']">
        </div>
        <div v-else>
          broken file
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchGroupUploads, fetchPastureGroupUploads } from "@/api/EvidencingAPI";
import JSZip from "jszip";
import { mapState } from "vuex";
import { IS_RANCHFORCE } from "@/constants";

const MIME_FILE_MAP = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  csv: 'text/csv',
  pdf: 'application/pdf'
}

export default {
  name: "EvidencingGroupValidation",
  components: {},
  data() {
    return {
      fileUrls: [],
      fileEmbeds: [],
      isRanchForce: IS_RANCHFORCE,
    }
  },
  computed: {
    ...mapState({
      user: state => state.User.user
    }),
    isSuperUser() {
      console.log('user', this.user);
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      
      return false;
    },
  },
  methods: {
    async getAllGroupUploads() {
      if (this.$route.params['groupId'] != null) {
        const groupId = this.$route.params['groupId'];  

        const fetchGroupUploadsAPI = this.isRanchForce ? fetchPastureGroupUploads : fetchGroupUploads;
        fetchGroupUploadsAPI({ id: groupId })
        .then(response => {
          const { data } = response;
          this.fileUrls = data;
        })
        .catch(err => {
          console.log('oop!', err);
        })
      }
    },
    async getRawFileData(fileUrl) {
      const z = new JSZip();
      const r = await fetch(fileUrl);
      const d = await r.blob();
      const zip = await z.loadAsync(d);
      const fileSrcs = [];

      for (const filename in z['files']) {
        const fileExt = filename.split('.').pop();

        if (fileExt in MIME_FILE_MAP) {
          const d = await zip.file(filename).async('base64');
          const src = `data:${MIME_FILE_MAP[fileExt]};base64,${d}`;
          const fileDetail = { filename, src, fileExt };
          fileSrcs.push(fileDetail);
        }
      }

      return fileSrcs;
    }
  },
  mounted() {
    this.getAllGroupUploads();
    console.log('huh', this.isSuperUser);
  },
  watch: {
    isSuperUser: {
      handler(curr) {
        if (curr != null) {
          if (!curr) {
            // if the user isn't a superuser, reroute them
            location.href = "/evidencing";
          }
        }
      },
      deep: true
    },
    async fileUrls(curr) {
      this.fileEmbeds = [];

      for (const fileUrl of curr) {
        this.getRawFileData(fileUrl)
        .then(({ data }) => {
          this.fileEmbeds.push(...data);
        })
        .catch(err => {
          console.log('error parsing zips:', err);
          this.fileEmbeds.push(null);
        });
      }
    }
  }
}
</script>

<style scoped>
.file-viewer {
  padding: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #FFFFFF;
  border-radius: 6px;
  margin-top: 24px;
}
.file {
  padding: 16px;
  margin: 0 16px 16px 0;
  width: calc(25% - 12px);
  height: 300px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.file:nth-child(4n) {
  margin-right: 0;
}
</style>