import API from "./API"

const endpoint = "practice-confirmations"

const endpointPasture = "practice-confirmations-pasture"

export const getAllConfirmations = (params) =>
  API.get(`/${endpoint}/`, { params });

export const getEvidencingByFieldIds = (params) =>
  API.post(`/${endpoint}/get_evidencing_by_field_ids/`, params);

export const getRelevantFields = (params) => 
  API.post(`/${endpoint}/get_relevant_fields/`, params);

export const getUploaderSpec = (params) => 
  API.post(`/${endpoint}/get_uploader_spec/`, params);

export const modifyConfirmationNotes = (params) =>
  API.post(`/${endpoint}/modify_notes/`, params);

export const fetchFieldCrops = (params) => 
  API.post(`/${endpoint}/fetch_field_crops/`, params);

export const createClientBasedGroups = (params) => 
  API.post(`/${endpoint}/create_client_based_evidencing_groups/`, params);

export const fetchClientBasedGroups = (params) => 
  API.post(`/${endpoint}/fetch_org_node_evidencing_groups_overview/`, params);

export const fetchEvidencingWithinGroup = (params) =>
  API.post(`/${endpoint}/fetch_evidencing_within_group/`, params);

export const fetchGroupData = (params) => 
  API.post(`/${endpoint}/fetch_group_data/`, params);

export const updateGroupMetadata = (params) => 
  API.post(`/${endpoint}/update_group_metadata/`, params);

export const updateGroupFieldCrops = (params) =>
  API.post(`/${endpoint}/update_group_field_crops/`, params);

export const fetchGroupUploads = (params) => 
  API.post(`/${endpoint}/fetch_group_uploads/`, params);

export const fetchPastureFieldCrops = (params) => 
  API.post(`/${endpointPasture}/fetch_field_crops/`, params);

export const getPastureEvidencingByFieldIds = (params) =>
  API.post(`/${endpointPasture}/get_evidencing_by_field_ids/`, params);

export const createPastureClientBasedGroups = (params) => 
  API.post(`/${endpointPasture}/create_client_based_evidencing_groups/`, params);

export const fetchPastureGroupData = (params) => 
  API.post(`/${endpointPasture}/fetch_group_data/`, params);

export const fetchPastureGroupUploads = (params) => 
  API.post(`/${endpointPasture}/fetch_group_uploads/`, params);

export const fetchPastureClientBasedGroups = (params) => 
  API.post(`/${endpointPasture}/fetch_org_node_evidencing_groups_overview/`, params);
