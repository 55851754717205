export const UPLOAD_DATA_ACTION = "Upload Data";
export const ASSIGN_USERS_ACTION = "Assign Users";
export const EXPORT_TABLE_ACTION = "Export";
export const UNTRACKED_EVIDENCING = "Untracked";

export const CLIENT_SPECIFICITY = "Client";
export const FIELD_SPECIFICITY = "Field";
export const FARM_SPECIFICITY = "Farm";

export const EVIDENCING_REMOVAL = "remove";
export const EVIDENCING_CREATION = "create";

export const LOADING_SUCCESS = 0;
export const LOADING_FAILURE = 1;
export const LOADING_PROCESSING = 2;

// not ideal, but for now we're hard coding date deadlines for evidencing
// date editing is possible, but configuring on creation is not scoped out yet
// dates confirmed w/ ariah, referenced here as of June 13 2024
// https://docs.google.com/spreadsheets/d/1apL1gcBwPZIDwxHSd8nA0c9WD0LOVGKoywnvPPcgmEg/edit?gid=0#gid=0
export const EVIDENCING_DATE_RULES = {
    4: new Date('11/05/24').toLocaleDateString(), // corn
    5: new Date('11/05/24').toLocaleDateString(), // soybeans
    6: new Date('11/01/24').toLocaleDateString(), // rice
    7: new Date('11/05/24').toLocaleDateString(), // wheat
    30: new Date('07/15/24').toLocaleDateString(), // winter wheat
    14: new Date('10/05/24').toLocaleDateString(), // oats
    22: new Date('10/05/24').toLocaleDateString(), // barley
    // sugarbeets?
}