<template>
  <div>
    <div class="mb-4">
      <v-row>
        <v-col cols="11">
          <!-- card -->
          <v-card>
            <v-row>
              <v-col cols="2">
                <v-btn icon fab @click="confirmBeforeRouting('BackBtn')">
                  <v-icon> mdi-arrow-left </v-icon>
                </v-btn>
                <img :src="currentField.boundary_image" style="width: 80px" />
              </v-col>
              <v-col cols="4">
                <div>
                  <div class="d-flex">
                    <h1>{{ currentField.name }}</h1>
                    <v-tooltip v-if="currentField.is_initializing_or_processing" color="black" bottom max-width="200px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ml-2 pb-1" v-bind="attrs" v-on="on" color="orange">mdi-sync</v-icon>
                        </template>
                        <span>Field is processing: data may be incomplete.</span>
                    </v-tooltip>
                    <v-tooltip bottom color="black" max-width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2 pb-1" v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>
                        <span class="complete">Green</span> cells indicate your
                        data has been manually confirmed.
                        <br />
                        <span class="machine">Blue</span> cells indicate your
                        data has come from a machine source (such as MyJD.com)
                        or shapefile import. They can be confirmed manually.
                        <br />
                        <span class="pre-gen">Yellow</span> cells indicate your
                        data is coming from public data or a unconfirmed source
                        and needs to be confirmed manually.
                        <br />
                        <span class="arva-alert">Red</span> cells indicate no
                        data.
                      </span>
                    </v-tooltip>
                  </div>
                  <span> {{ currentField.acreage }} AC</span>
                  <span class="ml-5">
                    {{ currentClient.name }}
                  </span>
                  <span class="ml-5">
                    {{ currentField.farm.name }}
                  </span>
                </div>
              </v-col>
              <v-col class="d-flex" cols="6" align-self="center">
                <div class="align-self-center ranching-option">
                  <v-btn
                    class="ml-auto mr-4"
                    :class="{ active: !showRanchingAndLand }"
                    @click="handleShowRanchingAndLand(false)"
                  >
                    Ranching
                  </v-btn>
                  <v-btn
                    class="ml-auto mr-4"
                    :class="{ active: showRanchingAndLand }"
                    @click="handleShowRanchingAndLand(true)"
                  >
                    Ranching + Land
                  </v-btn>
                </div>
                <v-btn
                  v-if="!isReadOnly"
                  class="ml-auto"
                  icon
                  fab
                  @click="handleDownload"
                >
                  <v-icon> mdi-tray-arrow-down </v-icon>
                </v-btn>
                <v-btn
                  x-large
                  v-if="currentField && !isReadOnly"
                  class="ml-auto mr-4 lighten-3"
                  @click="setAdditionalYearModalOpen = true"
                >
                  <v-icon>mdi-plus</v-icon> Add Year
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col class="px-0 py-0" cols="1">
          <v-btn
            x-small
            class="arrowBtn"
            @click="confirmBeforeRouting('PrevFieldBtn')"
            :disabled="disablePreviousFieldBtn"
          >
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          <v-btn
            x-small
            class="ml-3 arrowBtn"
            @click="confirmBeforeRouting('NextFieldBtn')"
            :disabled="disableNextFieldBtn"
          >
            <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- table control buttons -->
    <div class="py-4">
      <v-row no-gutters class="sqr-btn-header">
        <v-col
          v-if="showRanchingAndLand"
          cols="12"
          class="d-flex justify-space-between"
        >
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'forage-table' }"
            @click="handleHeaderButtonClick('forage-table', 'forage-table')"
          >Forage</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'livestock-table' }"
            @click="handleHeaderButtonClick('livestock-table', 'livestock-table')"
          >Livestock</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'stocking-table' }"
            @click="handleHeaderButtonClick('stocking-table', 'stocking-table')"
          >Stocking</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'grazing-table' }"
            @click="handleHeaderButtonClick('grazing-table', 'grazing-table')"
          >Grazing</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'feed-table' }"
            @click="handleHeaderButtonClick('feed-table', 'feed-table')"
          >Feed</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'biomass-table' }"
            @click="handleHeaderButtonClick('biomass-table', 'biomass-table')"
          >Biomass</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'fertilizer-table' }"
            @click="handleHeaderButtonClick('fertilizer-table', 'fertilizer-table')"
          >Fertilizer</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'liming-table' }"
            @click="handleHeaderButtonClick('liming-table', 'liming-table')"
          >Liming</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'protection-table' }"
            @click="handleHeaderButtonClick('protection-table', 'protection-table')"
          >Protection</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'invaded-table' }"
            @click="handleHeaderButtonClick('invaded-table', 'invaded-table')"
          >Invaded/Degraded</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'burn-table' }"
            @click="handleHeaderButtonClick('burn-table', 'burn-table')"
          >Burn</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'irrigation-table' }"
            @click="handleHeaderButtonClick('irrigation-table', 'irrigation-table')"
          >Irrigation</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'seed-table' }"
            @click="handleHeaderButtonClick('seed-table', 'seed-table')"
          >Seeding/Interseeding</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'sampling-table' }"
            @click="handleHeaderButtonClick('sampling-table', 'sampling-table')"
          >Sampling</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'supply-table' }"
            @click="handleHeaderButtonClick('supply-table', 'supply-table')"
          >Supply Shed</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'bqa-table' }"
            @click="handleHeaderButtonClick('bqa-table', 'bqa-table')"
          >BQA</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'technology-table' }"
            @click="handleHeaderButtonClick('technology-table', 'technology-table')"
          >Technology</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'conservation-table' }"
            @click="handleHeaderButtonClick('conservation-table', 'conservation-table')"
          >Conservation/Other Program</v-btn>
        </v-col>
        <v-col
          v-if="!showRanchingAndLand"
          cols="12"
          class="d-flex"
        >
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'forage-table' }"
            @click="handleHeaderButtonClick('forage-table', 'forage-table')"
          >Forage</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'livestock-table' }"
            @click="handleHeaderButtonClick('livestock-table', 'livestock-table')"
          >Livestock</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'stocking-table' }"
            @click="handleHeaderButtonClick('stocking-table', 'stocking-table')"
          >Stocking</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'grazing-table' }"
            @click="handleHeaderButtonClick('grazing-table', 'grazing-table')"
          >Grazing</v-btn>
          <v-btn
            dense
            :class="{ active: activeHeaderButton === 'feed-table' }"
            @click="handleHeaderButtonClick('feed-table', 'feed-table')"
          >Feed</v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- loader for table -->
    <div class="d-flex justify-center" v-if="dataReady == false">
      <div>
        <div class="load-spacer">
          <v-progress-circular
            :size="140"
            :width="8"
            color="green"
            indeterminate
          ></v-progress-circular>
        </div>
        <div class="mt-3">
          <h4 class="text-center">Loading Data...</h4>
        </div>
      </div>
    </div>

    <!-- table -->
    <b-table-simple
      v-if="dataReady"
      responsive
      bordered
      sticky-header="65vh"
      no-border-collapse
      width="100%"
    >
      <b-thead>
        <b-tr class="header-row">
          <b-th class="persist-col cell-table" sticky-column>
            <b-td class="year-cell">Year</b-td>
            <b-td class="crop-cell">Crop</b-td>
            <b-td
              v-if="
                !isReadOnly && !newFieldsByYear.every(f => f.year_data_locked)
              "
              class="actions-cell"
            >
              <div class="d-flex justify-center">
                <v-btn
                  class="action-button mt-3"
                  color="green"
                  icon
                  small
                  fab
                  @click="confirmLockAllYears"
                >
                  <v-tooltip top max-width="300"
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        class="lockall-button"
                        v-bind="attrs"
                        v-on="on"
                        size="20"
                      >
                        mdi-lock-open</v-icon
                      > </template
                    ><span
                      >Click to lock all years for this field.</span
                    ></v-tooltip
                  >
                </v-btn>
              </div>
            </b-td>
          </b-th>
          <!-- forage -->
          <b-th class="text-center planting-bg white--text cell-table" v-if="showForage">
            <b-td class="large-spread-col">Primary Forage</b-td>
            <b-td class="icon-col white"></b-td>
          </b-th>
          <!-- livestock -->
          <b-th
            class="text-center cell-table"
            v-if="showLivestock"
          >
            <b-td class="small-spread-col">Species</b-td>
            <b-td class="med-spread-col">Animal Detail</b-td>
            <b-td class="small-spread-col">Average Weight</b-td>
            <b-td class="small-spread-col">Weight Units</b-td>
            <b-td class="icon-col black"></b-td>
          </b-th>
          <!-- stocking -->
          <b-th class="text-center planting-bg white--text cell-table" v-if="showStocking">
            <b-td class="small-spread-col"># of Animals</b-td>
            <b-td class="icon-col white"></b-td>
          </b-th>
          <!-- grazing -->
          <b-th class="text-center cell-table" v-if="showGrazing">
            <b-td class="small-spread-col">Start Date</b-td>
            <b-td class="small-spread-col">End Date</b-td>
            <b-td class="small-spread-col">Rest Period</b-td>
            <b-td class="small-spread-col">Rotational Grazed</b-td>
            <b-td class="icon-col white"></b-td>
          </b-th>
          <!-- feed -->
          <b-th class="text-center planting-bg white--text cell-table" v-if="showFeed">
            <b-td class="med-spread-col">Expected Daily DMI</b-td>
            <b-td class="med-spread-col">Units</b-td>
            <b-td class="med-spread-col">Feed Additive</b-td>
            <b-td class="med-spread-col">Feed Method</b-td>
            <b-td class="small-spread-col">Amount</b-td>
            <b-td class="med-spread-col">Units</b-td>
            <b-td class="icon-col black"></b-td>
          </b-th>
          <!-- biomass -->
          <b-th class="text-center cell-table" v-if="showBiomass && showRanchingAndLand">
            <b-td class="med-spread-col">Removal Type</b-td>
            <b-td class="med-spread-col">Removal Date</b-td>
            <b-td class="small-spread-col">% Removed</b-td>
            <b-td class="icon-col white"></b-td>
          </b-th>
          <!-- fertilizer -->
          <b-th class="text-center planting-bg white--text  cell-table" v-if="showFertilizer && showRanchingAndLand">
            <b-td class="small-spread-col">Fertilizer Date</b-td>
            <b-td class="large-spread-col">Method</b-td>
            <b-td class="large-spread-col">Fertilizer</b-td>
            <b-td class="small-spread-col">Rate</b-td>
            <b-td class="small-spread-col">Units</b-td>
            <!-- <b-td class="med-spread-col">Cost</b-td> -->
            <b-td class="small-spread-col"
              >N <br /><span class="text-caption">lb/ac</span>
            </b-td>
            <b-td class="small-spread-col"
              >P <br /><span class="text-caption">lb/ac</span>
            </b-td>
            <b-td class="small-spread-col"
              >K <br /><span class="text-caption">lb/ac</span>
            </b-td>
            <b-td class="small-spread-col"
              >S <br /><span class="text-caption">lb/ac</span>
            </b-td>
            <b-td class="med-spread-col">Slow Release</b-td>
            <b-td class="med-spread-col">Inhibitor</b-td>
            <b-td class="small-spread-col">VRT</b-td>
            <b-td class="med-spread-col">Management Plan</b-td>
            <b-td class="med-spread-col">4R Compliant</b-td>
            <b-td class="icon-col white"></b-td>
          </b-th>
          <!-- liming -->
          <b-th
            class="text-center cell-table"
            v-if="showLiming && showRanchingAndLand"
          >
            <b-td class="small-spread-col">Liming Date</b-td>
            <b-td class="large-spread-col">Type</b-td>
            <b-td class="small-spread-col">Amount</b-td>
            <b-td class="icon-col black"></b-td>
          </b-th>
          <!-- Crop Protection -->
          <b-th class="text-center planting-bg white--text cell-table" v-if="showCropProtection && showRanchingAndLand">
            <b-td class="med-spread-col">Protection Date</b-td>
            <b-td class="small-spread-col">Method</b-td>
            <b-td class="small-spread-col">Type</b-td>
            <b-td class="small-spread-col">Product</b-td>
            <b-td class="small-spread-col">Rate</b-td>
            <b-td class="small-spread-col">Units</b-td>
            <b-td class="icon-col white"></b-td>
          </b-th>
          <!-- invaded/degraded -->
          <b-th
            class="text-center cell-table"
            v-if="showInvaded && showRanchingAndLand"
          >
            <b-td class="small-spread-col">% Invaded</b-td>
            <b-td class="icon-col white"></b-td>
          </b-th>
          <!-- Burn -->
          <b-th class="text-center planting-bg white--text cell-table" v-if="showBurn && showRanchingAndLand">
            <b-td class="large-spread-col">Most Recent Burn Date</b-td>
            <b-td class="small-spread-col">% Burned</b-td>
            <b-td class="med-spread-col"># of Fires last 10 yrs</b-td>
            <b-td class="icon-col black"></b-td>
          </b-th>
          <!-- irrigation -->
          <b-th
            class="text-center cell-table"
            v-if="showIrrigation && showRanchingAndLand"
          >
            <b-td class="med-spread-col">Start Irrigation</b-td>
            <b-td class="med-spread-col">End Irrigation</b-td>
            <b-td class="med-spread-col">Method Type</b-td>
            <b-td class="small-spread-col">Amount</b-td>
            <b-td class="small-spread-col">Units</b-td>
            <b-td class="icon-col"></b-td>
          </b-th>
          <!-- Seeding -->
          <b-th class="text-center planting-bg white--text cell-table" v-if="showSeeding && showRanchingAndLand">
            <b-td class="small-spread-col">Plant Date</b-td>
            <b-td class="large-spread-col">Family</b-td>
            <b-td class="small-spread-col">Type</b-td>
            <b-td class="small-spread-col">Interseeded</b-td>
            <b-td class="icon-col black"></b-td>
          </b-th>
          <!-- Soil Sampling -->
          <b-th class="text-center cell-table" v-if="showSampling && showRanchingAndLand">
            <b-td class="med-spread-col">Soil Sample Date</b-td>
            <b-td class="med-spread-col">Soil Sample Type</b-td>
            <b-td class="med-spread-col">Forage Sample Date</b-td>
            <b-td class="med-spread-col">Forage Sample Type</b-td>
            <b-td class="icon-col white"></b-td>
          </b-th>
          <!-- Supply Shed -->
          <b-th class="text-center planting-bg white--text cell-table" v-if="showSupply && showRanchingAndLand">
            <b-td class="med-spread-col">State of Origin (for feedlots)</b-td>
            <b-td class="med-spread-col">State of Destination</b-td>
            <b-td class="icon-col black"></b-td>
          </b-th>
          <!-- BQA -->
          <b-th class="text-center cell-table" v-if="showBqa && showRanchingAndLand">
            <b-td class="small-spread-col">Beef Quality Assurance</b-td>
            <b-td class="icon-col white"></b-td>
          </b-th>
          <!-- Technology -->
          <b-th class="text-center planting-bg white--text cell-table" v-if="showTechnology && showRanchingAndLand">
            <b-td class="xlarge-spread-col">Technology</b-td>
            <b-td class="icon-col black"></b-td>
          </b-th>
          <!-- Conservation/Other Program -->
          <b-th class="text-center cell-table" v-if="showConservation && showRanchingAndLand">
            <b-td class="small-spread-col">RCCP</b-td>
            <b-td class="small-spread-col">CSCG</b-td>
            <b-td class="small-spread-col">EQUIP</b-td>
            <b-td class="small-spread-col">CSP</b-td>
            <b-td class="small-spread-col">Pay for Practice</b-td>
            <b-td class="small-spread-col">Carbon Offset</b-td>
            <b-td class="small-spread-col">Other Scope</b-td>
            <b-td class="small-spread-col">Biodiversity Acres</b-td>
            <b-td class="icon-col white"></b-td>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <SpreadsheetRanchRow
          v-for="fieldObj in newFieldsByYear"
          :key="fieldObj.idx"
          :rowNum="fieldObj.yearIndex"
          :rowSpan="
            fieldObj.totalCultivations - 1 == fieldObj.yearIndex
              ? newFieldsByYear.length
              : 0
          "
          :field="fieldObj"
          :years="seasonOptions"
          :allFields="allFields"
          :selectedFields="selectedFields"
          
          :showLivestock="showLivestock"
          :showGrazing="showGrazing"
          :showFeed="showFeed"
          :showBiomass="showBiomass"
          :showFertilizer="showFertilizer"
          :showLiming="showLiming"
          :showCropProtection="showCropProtection"
          :showInvaded="showInvaded"
          :showBurn="showBurn"
          :showIrrigation="showIrrigation"
          :showSeeding="showSeeding"
          :showSampling="showSampling"
          :showSupply="showSupply"
          :showBqa="showBqa"
          :showTechnology="showTechnology"
          :showConservation="showConservation"
          :showForage="showForage"
          :showStocking="showStocking"
          :showRanchingAndLand="showRanchingAndLand"
          
          :totalCropByYear="totalCropByYear[fieldObj.year]"
          @refresh-overwrite="refreshSpreadSheetWithOverride"
          @display-save="emphasizeSave = true"
          @bad-input="handleBadInput"
          @lock-year-data="confirmLockYear"
          @delete-year-data="confirmDeleteYear"
        />
      </b-tbody>
    </b-table-simple>

    <div v-if="!isReadOnly" class="save-footer">
      <div class="save-bar row">
        <div class="col d-flex" align-self="center">
          <v-btn class="save-btns ml-auto mr-5" @click="closeWithoutSaving"
            >Close without Saving</v-btn
          >
          <v-btn class="save-btns mr-5" @click="saveAndClose"
            >Save & Close</v-btn
          >
          <v-btn class="save-btns final-save-btn" @click="saveAndContinue"
            >Save</v-btn
          >
        </div>
      </div>
    </div>

    <SpreadSheetExtensionModal
      v-if="setAdditionalYearModalOpen"
      :field="currentField"
      :years="fieldId"
      @close-template-modal="setAdditionalYearModalOpen = false"
      @submit-year-append="refreshSpreadSheet"
    />

    <SaveLoaderModal
      v-if="saveLoaderModalOpen"
      :loading="saveLoaderModalLoading"
      :loadComplete="saveLoaderModalLoadComplete"
      loadCompleteText="Field Updated!"
      savingText="Saving your updates..."
      titleText="Saving Field Settings"
      @close-template-modal="saveLoaderModalOpen = false"
    />

    <ConfirmModal
      v-if="confirmBeforeLeavingModalOpen"
      titleText="Confirm Save Changes"
      confirmText="Do you want to save your changes before leaving?"
      :isClosingWithoutSaving="confirmBeforeLeavingModalOpen"
      @close-without-saving="closeWithoutSaving"
      @confirm="saveAndThenRouting"
      @close-modal="closeAndThenRouting"
    />

    <ConfirmModal
      v-if="confirmSaveChangesModalOpen"
      titleText="Confirm Save Changes"
      confirmText="Do you want to save your changes?"
      @confirm="handleSave"
      @close-modal="confirmSaveChangesModalOpen = false"
    />

    <ConfirmModal
      v-if="confirmCloseWithoutSavingModalOpen"
      titleText="Confirm Close Without Save Changes"
      confirmText="Do you want to save your changes before leaving?"
      @confirm="navigateBackToPreviousPage"
      @close-modal="confirmCloseWithoutSavingModalOpen = false"
    />

    <ConfirmModal
      v-if="confirmDeleteYearModalOpen"
      titleText="Confirm Delete Year"
      confirmText="Are you sure you want to delete this year data?"
      :isProcessing="isProcessing"
      @confirm="deleteYearData"
      @close-modal="confirmDeleteYearModalOpen = false"
    />

    <ConfirmModal
      v-if="confirmLockYearModalOpen"
      :titleText="yearDataTitleText"
      :confirmText="yearDataConfirmText"
      :isProcessing="isProcessing"
      @confirm="lockYearData"
      @close-modal="confirmLockYearModalOpen = false"
    />

    <v-snackbar v-model="showSnack">
      {{ snackErrorText ? snackErrorText : "Something went wrong!" }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import _ from "lodash"
import SpreadsheetRanchRow from "@/components/spreadsheet/SpreadsheetRanchRow"
import { Defaults, Pastures, Filter } from "@/store/modules"
import SaveLoaderModal from "@/components/modals/SaveLoaderModal"
import SpreadSheetExtensionModal from "@/components/modals/SpreadSheetExtensionModal"
import router from "@/router"
import PasturesAPI from "@/api/PasturesAPI"
import ConfirmModal from "@/components/modals/ConfirmModal"

export default {
  components: {
    SaveLoaderModal,
    SpreadsheetRanchRow,
    SpreadSheetExtensionModal,
    ConfirmModal,
  },

  data() {
    return {
      clickedBackBtn: false,
      clickedPrevFieldBtn: false,
      clickedNextFieldBtn: false,
      confirmBeforeLeavingModalOpen: false,
      confirmSaveChangesModalOpen: false,
      confirmCloseWithoutSavingModalOpen: false,
      confirmLockYearModalOpen: false,
      confirmDeleteYearModalOpen: false,
      saveLoaderModalOpen: false,
      saveLoaderModalLoading: false,
      saveLoaderModalLoadComplete: false,
      setAdditionalYearModalOpen: false,
      emphasizeSave: false,
      showSnack: false,
      snackErrorText: null,

      showLivestock: true,
      showGrazing: true,
      showFeed: true,
      showBiomass: true,
      showFertilizer: true,
      showLiming: true,
      showCropProtection: true,
      showInvaded: true,
      showBurn: true,
      showIrrigation: true,
      showSeeding: true,
      showSampling: true,
      showSupply: true,
      showBqa: true,
      showTechnology: true,
      showConservation: true,
      showForage: true,
      showStocking: true,

      saveOption: null,
      // next promise holder
      promiseHolder: null,
      skipRouteGuard: false,
      lockYearDataPayload: null,
      deleteYearDataPayload: null,
      isProcessing: false,
      yearDataTitleText: null,
      yearDataConfirmText: null,
      // prevent loading unnecessarily
      dataReady: false,
      activeHeaderButton: '',
      showRanchingAndLand: false,
    }
  },
  props: ["fieldId"],

  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      years: state => state.Organization.organization.years,
      fieldSettings: state => state.Defaults.fieldSettings,
      spreadsheetNeedsSave: state => state.Defaults.spreadsheetNeedsSave,
      user: state => state.User.user,
    }),

    ...mapGetters({
      allFields: Pastures.Getters.getFields,
      selectedFields: Filter.Getters.getSelectedFields,
      clients: Filter.Getters.getClients,
      farms: Filter.Getters.getFarms,
      currentFieldSettings: Defaults.Getters.getOneFieldSettings,
      allNewFieldSettings: Defaults.Getters.getNewFieldSettings,
    }),
    sortFields() {
      const sortValues = {
        acreage: null,
        "farm.name": null,
        "defaults.name": null,
        name: null,
      }
      let sortColumns = []
      let sortDirections = []
      for (const column in sortValues) {
        if (sortValues[column] !== null) {
          sortColumns.push(column)
          sortDirections.push(sortValues[column])
        }
      }
      const res = _.orderBy(this.selectedFields, sortColumns, sortDirections)
      return res
    },
    disablePreviousFieldBtn() {
      if (this.sortFields[0].id == this.fieldId) return true
      return false
    },
    disableNextFieldBtn() {
      if (this.sortFields[this.sortFields.length - 1].id == this.fieldId)
        return true
      return false
    },
    cameFromEnrollment() {
      if (router.history.router.prevRoute.path === "/enroll-fields") return true
      return false
    },

    cameFromEnrollManager() {
      if (router.history.router.prevRoute.path === "/enroll-manager")
        return true
      return false
    },

    seasonOptions() {
      return this.newFieldsByYear.map(setting => setting.year)
    },

    currentField() {
      return this.selectedFields.filter(obj => obj.id == this.fieldId)[0]
    },
    currentClient() {
      const farm = this.farms.filter(
        obj => obj.id == this.currentField.farm.id
      )[0]
      const client = this.clients.filter(obj => obj.id == farm.clientId)[0]
      return client
    },

    newFieldsByYear() {
      let carbonArr = []
      if (this.currentFieldSettings(this.fieldId) != undefined) {
        this.currentFieldSettings(this.fieldId).forEach(totalFieldSetting => {
          const fieldKey = totalFieldSetting[0]

          const selField = this.selectedFields.find(
            field => field.id == parseInt(fieldKey)
          )

          if (!selField) {
            return
          }
          const fieldSettingObj = totalFieldSetting[1]

          let totalYears = fieldSettingObj.year_data.length
          let totalCultivations = fieldSettingObj.year_data
            .map(yearData => yearData.cultivations.length)
            .reduce((p, c) => p + c, 0)

          if (this.user.is_in_shared_org) {
            totalYears = this.years.length
            totalCultivations = fieldSettingObj.year_data
              .filter(yearData => this.years.includes(yearData.year))
              .map(yearData => yearData.cultivations.length)
              .reduce((p, c) => p + c, 0)
          }

          const idSettings = {
            fieldId: fieldKey,
            fieldName: selField.name,
            fieldImg: selField.boundary_image,
            fieldSettingsId: fieldSettingObj.field_settings_id,
            fertilizerId: fieldSettingObj.fertilizer_id,
            cropProtectionId: fieldSettingObj.crop_protection_id,
            // harvestId: fieldSettingObj.harvest_id,
            operationId: fieldSettingObj.operation_id,
            // plantingId: fieldSettingObj.planting_id,
            samplingId: fieldSettingObj.soil_sampling_id,
            livestockId: fieldSettingObj.livestock_id,
            feedId: fieldSettingObj.feed_id,
            invadedId: fieldSettingObj.invaded_id,
            seedId: fieldSettingObj.seed_id,
            supplyId: fieldSettingObj.supply_id,
            bqaId: fieldSettingObj.bqa_id,
            burnId: fieldSettingObj.burn_id,
            technologyId: fieldSettingObj.technology_id,
            conservationId: fieldSettingObj.conservation_id,
            forageId: fieldSettingObj.forage_id,
            stockingId: fieldSettingObj.stocking_id,
            totalYears: totalYears,
            totalCultivations: totalCultivations,
          }
          let yearIndexCounter = 0
          fieldSettingObj.year_data.forEach(yearData => {
            // if user is on shared org, only show data in years that are shared
            if (this.user.is_in_shared_org) {
              if (this.years.includes(yearData.year)) {
                const cultivations = yearData.cultivations.sort(
                  (a, b) => b.arva_crop_id - a.arva_crop_id
                )
                cultivations.forEach(cultivation => {
                  let cropsOrdered = cultivations
                    .map(o => o.arva_crop_id)
                    .sort((a, b) => a - b)
                  const cultiv = Object.assign(cultivation, idSettings, {
                    allCrops: cropsOrdered,
                    year: String(yearData.year),
                    yearIndex: yearIndexCounter,
                  })
                  yearIndexCounter += 1
                  carbonArr.push(cultiv)
                })
              }
            } else {
              // Otherwise, show all years
              const cultivations = yearData.cultivations.sort(
                (a, b) => b.arva_crop_id - a.arva_crop_id
              )
              cultivations.forEach(cultivation => {
                let cropsOrdered = cultivations
                  .map(o => o.arva_crop_id)
                  .sort((a, b) => a - b)
                const cultiv = Object.assign(cultivation, idSettings, {
                  allCrops: cropsOrdered,
                  year: String(yearData.year),
                  yearIndex: yearIndexCounter,
                })
                yearIndexCounter += 1
                carbonArr.push(cultiv)
              })
            }
          })
        })
      }
      return _.orderBy(carbonArr, ["yearIndex"], ["desc"])
    },
    totalCropByYear() {
      const cropIdsPerYear = {}
      this.newFieldsByYear.forEach(obj => {
        if (obj.year in cropIdsPerYear) {
          cropIdsPerYear[obj.year].add(obj.arva_crop_id)
        } else {
          cropIdsPerYear[obj.year] = new Set([obj.arva_crop_id])
        }
      })

      const totalCropIds = {}
      for (const year in cropIdsPerYear) {
        totalCropIds[year] = cropIdsPerYear[year].size
      }
      return totalCropIds
    },
  },

  methods: {
    ...mapActions({
      // fetchCarbonSettings: Defaults.Actions.fetchNewFieldSettings,
      fetchCarbonSettingsByField: Defaults.Actions.fetchCarbonSettingsByField,
      saveDataSpreadsheet: Defaults.Actions.saveDataSpreadsheetRanch,
      fetchField: Pastures.Actions.fetchField,
    }),
    ...mapMutations({
      resetNeedsSave: Defaults.Mutations.resetNeedsSave,
    }),
    closeSaveLoaderModal() {
      this.saveLoaderModalOpen = false
    },
    async refreshSpreadSheet() {
      this.dataReady = false
      await this.fetchCarbonSettingsByField({ fieldIds: [this.fieldId] })
      this.dataReady = true
    },
    async refreshSpreadSheetWithOverride() {
      this.saveLoaderModalOpen = true
      this.saveLoaderModalLoading = true
      await this.fetchCarbonSettingsByField({
        fieldIds: [this.fieldId],
        forceOverwrite: true,
      }).then(async () => {
        this.saveLoaderModalLoading = false
        this.saveLoaderModalLoadComplete = true
        const delay = ms => new Promise(res => setTimeout(res, ms))
        await delay(2000)
        this.saveLoaderModalOpen = false
        this.saveLoaderModalLoadComplete = false
      })
    },
    handleEnrollmentClick() {
      this.$router.push("/enroll-fields")
    },
    handleEnrollManagerClick() {
      this.$router.push("/enroll-manager")
    },
    confirmBeforeRouting(button) {
      if (button === "BackBtn") this.clickedBackBtn = true
      else if (button === "PrevFieldBtn") this.clickedPrevFieldBtn = true
      else if (button === "NextFieldBtn") this.clickedNextFieldBtn = true
      if (this.spreadsheetNeedsSave === true && !this.isReadOnly) {
        this.confirmBeforeLeavingModalOpen = true
      } else {
        this.skipRouteGuard = true
        this.closeAndThenRouting()
      }
    },
    async saveAndThenRouting() {
      this.confirmBeforeLeavingModalOpen = false
      await this.handleSave()
      if (this.promiseHolder) {
        this.promiseHolder()
      }
    },
    closeAndThenRouting() {
      this.confirmBeforeLeavingModalOpen = false
      if (this.clickedBackBtn) this.navigateBackToPreviousPage()
      else if (this.clickedPrevFieldBtn) this.goBackToPreviousField()
      else if (this.clickedNextFieldBtn) this.goForwardToNextField()
    },
    navigateBackToPreviousPage() {
      this.skipRouteGuard = true
      if (this.cameFromEnrollment) {
        this.handleEnrollmentClick()
      } else if (this.cameFromEnrollManager) {
        this.handleEnrollManagerClick()
      } else {
        this.$router.push("/fields")
      }
    },
    navigateToDataSpreadsheet(fieldId) {
      this.$router.push(`/data-spreadsheet/${fieldId}`)
    },
    goBackToPreviousField() {
      for (var index = 0; index < this.sortFields.length; index++) {
        if (this.sortFields[index].id == this.fieldId) {
          this.navigateToDataSpreadsheet(this.sortFields[index - 1].id)
          break
        }
      }
    },
    goForwardToNextField() {
      for (var index = 0; index < this.sortFields.length; index++) {
        if (this.sortFields[index].id == this.fieldId) {
          this.navigateToDataSpreadsheet(this.sortFields[index + 1].id)
          break
        }
      }
    },
    closeWithoutSaving() {
      this.navigateBackToPreviousPage()
    },
    async saveAndClose() {
      this.saveOption = "SaveAndClose"
      this.confirmSaveChangesModalOpen = true
    },
    async saveAndContinue() {
      this.saveOption = "SaveAndContinue"
      this.confirmSaveChangesModalOpen = true
    },

    async handleSave() {
      this.confirmSaveChangesModalOpen = false
      this.saveLoaderModalOpen = true
      this.saveLoaderModalLoading = true
      await this.saveDataSpreadsheet({ fieldId: this.fieldId }).then(
        async () => {
          const delay = ms => new Promise(res => setTimeout(res, ms))
          await delay(1500)
          this.saveLoaderModalLoading = false
          this.saveLoaderModalLoadComplete = true
          await delay(1500)
          this.saveLoaderModalOpen = false
          this.saveLoaderModalLoadComplete = false
        }
      )

      await this.fetchField({ fieldId: this.fieldId })

      this.resetNeedsSave()

      if (this.saveOption === "SaveAndClose" || this.clickedBackBtn)
        this.navigateBackToPreviousPage()
      else if (this.clickedPrevFieldBtn) this.goBackToPreviousField()
      else if (this.clickedNextFieldBtn) this.goForwardToNextField()
    },

    async handleDownload() {
      const payload = {
        ranchingAndLand: this.showRanchingAndLand
      }
      await PasturesAPI.downloadDataSpreadsheet({ fieldId: this.fieldId, payload }).then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `${this.fieldId}_Pasture-Field_Report.csv`)
          document.body.appendChild(link)
          link.click()
        }
      )
    },

    handleShowRanchingAndLand(cond) {
      this.showRanchingAndLand = cond;
    },

    handleBadInput(errorText) {
      this.showSnack = true
      this.snackErrorText = errorText
    },

    handleHeaderButtonClick(button, tableId) {
      this.activeHeaderButton = button;
      this.handleScrollTo(tableId);
    },

    handleScrollTo(elementName) {
      let topRowNum = this.newFieldsByYear.length - 1
      if (topRowNum < 0) {
        topRowNum = 0;
      }
      let myElement = document.getElementById(elementName + "-" + topRowNum)
      if (myElement) {
        myElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        })
      }
    },

    confirmDeleteYear(payload) {
      this.deleteYearDataPayload = payload
      this.confirmDeleteYearModalOpen = true
    },

    async deleteYearData() {
      this.isProcessing = true
      await PasturesAPI.deleteYearData(this.deleteYearDataPayload).then(
        async () => {
          await this.fetchCarbonSettingsByField({
            fieldIds: [this.fieldId],
            forceOverwrite: true,
          })
        }
      )
      this.confirmDeleteYearModalOpen = false
      this.deleteYearDataPayload = null
      this.isProcessing = false
    },

    confirmLockAllYears() {
      this.lockYearDataPayloads = this.newFieldsByYear
        .filter(field => !field.year_data_locked)
        .map(field => {
          return {
            field_id: field.fieldId,
            year: field.year,
            crop_id: field.arva_crop_id,
            year_data_locked: true,
          }
        })
      this.yearDataTitleText = "Confirm Lock All Years"
      this.yearDataConfirmText = `Are you sure you want to lock all years data? These years will be newly locked: ${this.lockYearDataPayloads
        .map(payload => payload.year)
        .join(", ")}.
          By locking all years data, the system is prevented from overriding it with data from other sources, while users can still modify the data through this page.`
      this.confirmLockYearModalOpen = true
    },

    confirmLockYear(payload) {
      this.lockYearDataPayloads = [payload]
      if (payload.year_data_locked) {
        this.yearDataTitleText = "Confirm Lock Year Data"
        this.yearDataConfirmText =
          "Are you sure you want to lock this year data? By locking this year's data, the system is prevented from overriding it with data from other sources, while users can still modify the data through this page."
      } else {
        this.yearDataTitleText = "Confirm Unlock Year Data"
        this.yearDataConfirmText =
          "Are you sure you want to unlock this year data? Unlocking this year's data allows the system to override it with data from other sources."
      }
      this.confirmLockYearModalOpen = true
    },

    async lockYearData() {
      this.isProcessing = true
      if (this.lockYearDataPayloads.length > 1) {
        // throw exception if year_data_locked is not true for all years
        if (
          !this.lockYearDataPayloads.every(
            yearData => yearData.year_data_locked
          )
        )
          throw new Error(
            "Cannot lock year data if year data is not locked for all years"
          )
      }

      for (const payload of this.lockYearDataPayloads) {
        await PasturesAPI.lockYearData(payload).then(async () => {
          await this.fetchCarbonSettingsByField({
            fieldIds: [this.fieldId],
          })
        })
      }
      this.confirmLockYearModalOpen = false
      this.lockYearDataPayload = null
      this.isProcessing = false
    },
  },

  async mounted() {
    if (this.fieldId) {
      await this.fetchCarbonSettingsByField({ fieldIds: [this.fieldId] }).then(
        () => {
          this.dataReady = true
        }
      )
    }
    this.emphasizeSave = false
  },

  beforeRouteLeave(to, from, next) {
    this.promiseHolder = next

    // check for spreadsheet mutations
    if (this.spreadsheetNeedsSave === false) {
      next()
    }

    if (!this.skipRouteGuard) {
      this.confirmBeforeLeavingModalOpen = true
    } else {
      next()
    }
  },

  beforeDestroy() {
    // makes sure needs save is reset to false for smoother navigation
    this.resetNeedsSave()
  },
}
</script>
<style scoped>
h2 {
  font-size: 21px;
  font-weight: 500;
}

.carbon-table {
  height: 100%;
  /* min-height: 500px !important; */
}

.carbon-table th {
  font-weight: 400 !important;
  font-size: 18px;
}

.pre-gen {
  font-weight: bold;
  color: #fedb80;
}

.machine {
  font-weight: bold;
  color: #c0dce2;
}

.complete {
  font-weight: bold;
  color: #c7e3c1;
}

.arva-alert {
  font-weight: bold;
  color: #f7c0be;
}

.eighth-width {
  width: 12.5%;
}

/* table specific */
.cell-table {
  padding: 0;
}

.cell-table td {
  line-height: 1;
  height: 60px;
  min-height: 60px;
}

.image-col {
  min-width: 126px;
}

.persist-col {
  min-width: 250px;
}

.year-cell {
  min-width: 70px;
}

.crop-cell {
  min-width: 180px;
}

.actions-cell {
  min-width: 60px;
  max-width: 60px;
  padding: 0;
}
.action-button {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stoplight-col {
  min-width: 150px;
}

/* grazing column sizing */
.grazing-col {
  min-width: 180px;
  max-width: 180px;
}

.grazing-date-col {
  min-width: 460px;
  max-width: 460px;
}

.grazing-animal-col {
  min-width: 250px;
  max-width: 250px;
}

.grazing-stock-col {
  min-width: 250px;
  max-width: 250px;
}

/* liming column sizing */
.liming-date-col {
  min-width: 172px;
}

.liming-type-col {
  min-width: 240px;
}

.liming-col {
  min-width: 200px;
}

/* tillage column sizing */
.tillage-date-col {
  min-width: 174px;
}

.tillage-depth-col {
  min-width: 164px;
}

.tillage-prac-col {
  min-width: 260px;
}

/* planting column sizing */
.small-spread-col {
  min-width: 140px;
}

.large-spread-col {
  min-width: 240px;
}

.med-spread-col {
  min-width: 180px;
}

.xlarge-spread-col {
  min-width: 300px;
}

/* icon column sizing */
.icon-col {
  min-width: 80px;
  max-width: 80px;
}

.copy-col {
  min-width: 44px;
  max-width: 44px;
}

.add-col {
  min-width: 40px;
  max-width: 40px;
}

/* column bgs */
.grazing-bg {
  background-color: #fff5d6 !important;
}

.liming-bg {
  background-color: #edf9dc !important;
}

.tillage-bg {
  background-color: #e5d6c8 !important;
}

.planting-bg {
  background-color: #000000 !important;
}

.fert-bg {
  background-color: #dae6e7 !important;
}

.harvest-bg {
  background-color: #fcf1c5 !important;
}

.irrigation-bg {
  background-color: #dae6e7 !important;
}

.save-footer {
  position: absolute;
  bottom: 12px;
  right: -20px;
  width: 100%;
}

.save-bar {
  background-color: #000000 !important;
  margin-right: -30px;
  margin-left: -30px;
}

.final-save-btn {
  margin-right: 110px;
}

.save-btns {
  height: 30px !important;
  background-color: #808080 !important;
  color: #ffffff !important;
}

.save-btns:hover {
  background-color: #1e90ff !important;
  transition: 0.7s;
}

.arrowBtn {
  width: 10px !important;
  height: 104px !important;
}

.load-spacer {
  margin-top: 96px;
}

.sqr-btn-header {
  overflow-x: auto;
  padding-bottom: 10px;
}

.sqr-btn-header button {
  margin-right: 15px;
}

.ranching-option button.active,
.sqr-btn-header button.active {
  background-color: black;
  color: white;
}
</style>
