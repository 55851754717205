<template>
  <b-tr>
    <!-- <b-th v-if="rowSpan > 0" scope="row" :rowspan="rowSpan">
      <img :src="field.fieldImg" style="width: 100px" />
    </b-th> -->
    <b-td sticky-column class="cell-table crop-column">
      <b-table-simple class="mb-0 light-blue-bg">
        <b-tr v-for="(n, index) in numberOfRows" :key="index">
          <b-td class="cell-table year-cell">
            <div v-if="n == 1">
              {{ fieldYear }}
            </div>
          </b-td>
          <b-td class="cell-table crop-cell">
            <v-select
              v-if="n == 1"
              dense
              hide-details="true"
              :items="filteredCropChoices"
              item-text="display_name"
              item-value="value"
              v-model="cultivationCrop"
            />
          </b-td>
          <b-td v-if="!isReadOnly" class="cell-table actions-cell">
            <div class="icons-container">
              <v-btn
                v-if="n === 1"
                v-model="field.year_data_locked"
                :color="field.year_data_locked ? 'red' : 'green'"
                icon
                small
                fab
                class="action-button"
                @click="handleLockYearData"
              >
                <v-tooltip top max-width="300"
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon v-bind="attrs" v-on="on" size="20">
                      {{
                        field.year_data_locked ? "mdi-lock" : "mdi-lock-open"
                      }}</v-icon
                    > </template
                  ><span
                    >The Lock feature allows users to lock this year data and
                    prevent the system from overriding data with other
                    sources.</span
                  ></v-tooltip
                >
              </v-btn>

              <v-btn
                v-if="n === 1 && totalCropByYear != 1"
                color="red"
                icon
                small
                fab
                class="action-button"
                @click="handleDeleteYearData"
              >
                <v-icon size="20">mdi-delete</v-icon>
              </v-btn>

              <v-tooltip
                v-else-if="n === 1 && totalCropByYear === 1"
                top
                max-width="300"
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon v-bind="attrs" v-on="on" size="20" color="grey"
                    >mdi-delete</v-icon
                  ></template
                ><span
                  >To ensure that each year has at least one assigned crop, if
                  no crops are planted, please select "Fallow" as the Crop
                  option for this year.</span
                ></v-tooltip
              >
            </div>
          </b-td>
        </b-tr>
      </b-table-simple>
    </b-td>
    <!-- forage -->
    <RanchForageTable
      v-if="showForage"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :forageData="newForageForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- livestock -->
    <RanchLivestockTable
      v-if="showLivestock"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :livestockData="newLivestockForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- forage -->
    <RanchStockingTable
      v-if="showStocking"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :stockingData="newStockingForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- grazing -->
    <RanchGrazingTable
      v-if="showGrazing"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :grazingData="newGrazingForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- feed -->
    <RanchFeedTable
      v-if="showFeed"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :feedData="newFeedForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- biomass -->
    <RanchBiomassTable
      v-if="showBiomass && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :biomassData="newBiomassForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- fertilizer -->
    <RanchFertilizerTable
      v-if="showFertilizer && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :fertilizerData="newFertilizerData"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- liming -->
    <RanchLimingTable
      v-if="showLiming && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :limingData="newLimingForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- crop protection -->
    <RanchCropProtectionTable
      v-if="showCropProtection && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :cropProtectionData="cropProtectionForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- invaded -->
    <RanchInvadedTable
      v-if="showInvaded && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :invadedData="newInvadedForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- burn -->
    <RanchBurnTable
      v-if="showBurn && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :burnData="newBurnForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- irrigation -->
    <RanchIrrigationTable
      v-if="showIrrigation && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :irrigationData="newIrrigationForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- Seeding -->
    <RanchSeedTable
      v-if="showSeeding && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :seedData="newSeedingForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- soil sampling -->
    <RanchSoilSampleTable
      v-if="showSampling && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :soilSampleData="soilSamplingForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
    />
    <!-- Supply -->
    <RanchSupplyTable
      v-if="showSupply && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :supplyData="newSupplyForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- Bqa -->
    <RanchBqaTable
      v-if="showBqa && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :bqaData="newBqaForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- Technology -->
    <RanchTechnologyTable
      v-if="showTechnology && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :technologyData="newTechnologyForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- Conservation -->
    <RanchConservationTable
      v-if="showConservation && showRanchingAndLand"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :conservationData="newConservationForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
  </b-tr>
</template>
<script>
import { mapMutations, mapState } from "vuex"

import { CROP_KEY, CROP_DROPDOWN_OPTIONS } from "@/constants"
import { Defaults } from "@/store/modules"
import DefaultsAPI from "@/api/DefaultsAPI"

import RanchLivestockTable from "@/components/spreadsheet/RanchLivestockTable"
import RanchGrazingTable from "@/components/spreadsheet/RanchGrazingTable"
import RanchFeedTable from "@/components/spreadsheet/RanchFeedTable"
import RanchBiomassTable from "@/components/spreadsheet/RanchBiomassTable"
import RanchFertilizerTable from "@/components/spreadsheet/RanchFertilizerTable"
import RanchLimingTable from "@/components/spreadsheet/RanchLimingTable"
import RanchCropProtectionTable from "@/components/spreadsheet/RanchCropProtectionTable"
import RanchInvadedTable from "@/components/spreadsheet/RanchInvadedTable"
import RanchBurnTable from "@/components/spreadsheet/RanchBurnTable"
import RanchIrrigationTable from "@/components/spreadsheet/RanchIrrigationTable"
import RanchSeedTable from "@/components/spreadsheet/RanchSeedTable"
import RanchSoilSampleTable from "@/components/spreadsheet/RanchSoilSampleTable"
import RanchSupplyTable from "@/components/spreadsheet/RanchSupplyTable"
import RanchBqaTable from "@/components/spreadsheet/RanchBqaTable"
import RanchTechnologyTable from "@/components/spreadsheet/RanchTechnologyTable"
import RanchConservationTable from "@/components/spreadsheet/RanchConservationTable"
import RanchForageTable from "@/components/spreadsheet/RanchForageTable"
import RanchStockingTable from "@/components/spreadsheet/RanchStockingTable"

export default {
  name: "SpreadsheetRow",
  components: {
    RanchLivestockTable,
    RanchGrazingTable,
    RanchFeedTable,
    RanchBiomassTable,
    RanchFertilizerTable,
    RanchLimingTable,
    RanchCropProtectionTable,
    RanchInvadedTable,
    RanchBurnTable,
    RanchIrrigationTable,
    RanchSeedTable,
    RanchSoilSampleTable,
    RanchSupplyTable,
    RanchBqaTable,
    RanchTechnologyTable,
    RanchConservationTable,
    RanchForageTable,
    RanchStockingTable,
  },

  props: {
    allFields: { type: Array },
    field: { type: Object },
    rowNum: { type: Number },
    rowSpan: { type: Number },
    selectedFields: { type: Array },
    years: { type: Array },

    showLivestock: { type: Boolean},
    showGrazing: { type: Boolean},
    showFeed: { type: Boolean},
    showBiomass: { type: Boolean},
    showFertilizer: { type: Boolean},
    showLiming: { type: Boolean},
    showCropProtection: { type: Boolean},
    showInvaded: { type: Boolean},
    showBurn: { type: Boolean},
    showIrrigation: { type: Boolean},
    showSeeding: { type: Boolean},
    showSampling: { type: Boolean},
    showSupply: { type: Boolean},
    showBqa: { type: Boolean},
    showTechnology: { type: Boolean},
    showConservation: { type: Boolean},
    showForage: { type: Boolean},
    showStocking: { type: Boolean},
    
    totalCropByYear: { type: Number },
    showRanchingAndLand: { type: Boolean },
  },

  data() {
    return {
      cropChoices: CROP_DROPDOWN_OPTIONS,
    }
  },

  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
    }),
    filteredCropChoices() {
      const excludeCrop = this.field.allCrops.filter(
        crop => crop != this.field.arva_crop_id
      )
      if (excludeCrop.length > 0) {
        return this.cropChoices.filter(o => o.value != excludeCrop[0])
      }
      return this.cropChoices
    },
    cultivationCrop: {
      get() {
        return this.field.arva_crop_id
      },
      async set(val) {
        const updatePayload = {
          field_id: this.field.fieldId,
          year: this.field.year,
          previous_crop: CROP_KEY[this.field.arva_crop_id],
          new_crop: CROP_KEY[val],
        }
        this.field.arva_crop_id = val
        await DefaultsAPI.updateFieldCropByFieldYear(updatePayload)
          .then(() => {
            this.$emit("refresh-overwrite")
          })
          .catch(error => console.log(error))
      },
    },

    // coverCropForYear: {
    //   get() {
    //     return this.field.operations.cover_crop.cover_crop_exists.value
    //   },
    //   set(val) {
    //     this.field.operations.cover_crop.cover_crop_exists.value = val
    //     this.updateNewFieldSetting({
    //       fieldId: this.field.fieldId,
    //       cropId: this.field.arva_crop_id,
    //       dataCategory: "cover_crop",
    //       dataSetting: "cover_crop_exists",
    //       rowIndex: null,
    //       value: val,
    //       year: this.field.year,
    //     })
    //   },
    // },

    fieldSetting() {
      return this.field.fieldSetting
    },

    // NEW PREGENERATED-FORMAT
    newFieldSetting() {
      return this.field.fieldId
    },

    // fieldSettingsTemplate() {
    //   return this.tempField.field_settings
    //     ? this.fieldSettings[this.tempField.field_settings.id]
    //     : null
    // },

    fieldYear() {
      return this.field.year
    },

    // OPERATIONAL DATA (DRAINAGE, GRAZING, LIMING, TILLAGE)
    // NEW PREGENERATED-FORMAT
    newOperationData() {
      return this.field.operations
    },

    newPlantingData() {
      return this.field.plantings
    },

    newFertilizerData() {
      return this.field.fert_applications
    },

    newHarvestData() {
      return this.field.harvests
    },

    newDrainageForYear: {
      get() {
        return this.newOperationData.drainage.drainage_exists.value
      },
      set(val) {
        this.newOperationData.drainage.drainage_exists.value = val
      },
    },

    newLivestockForYear() {
      return this.field.livestock
    },

    newGrazingForYear() {
      return this.newOperationData.grazings
    },

    newFeedForYear() {
      return this.field.feed
    },

    newBiomassForYear() {
      return this.newOperationData.biomass
    },

    newLimingForYear() {
      return this.newOperationData.limings
    },

    cropProtectionForYear() {
      return this.field.crop_protection
    },

    newInvadedForYear() {
      return this.field.invaded
    },

    newBurnForYear() {
      return this.field.burn
    },

    newIrrigationForYear() {
      return this.newOperationData.irrigations
    },

    newSeedingForYear() {
      return this.field.seed
    },

    soilSamplingForYear() {
      return this.field.soil_sampling
    },

    newSupplyForYear() {
      return this.field.supply
    },

    newBqaForYear() {
      return this.field.bqa
    },

    newTechnologyForYear() {
      return this.field.technology
    },

    newConservationForYear() {
      return this.field.conservation
    },

    newForageForYear() {
      return this.field.forage
    },

    newStockingForYear() {
      return this.field.stocking
    },

    numberOfRows() {
      if (this.newFertilizerData.length == 0) {
        return 1
      }
      return this.newFertilizerData.length
    },
  },

  methods: {
    ...mapMutations({
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
    }),
    handleBadInput(errorText) {
      this.$emit("bad-input", errorText)
    },
    handleDeleteYearData() {
      const payload = {
        field_id: this.field.fieldId,
        year: this.field.year,
        crop_id: this.cultivationCrop,
      }
      this.$emit("delete-year-data", payload)
    },
    handleLockYearData() {
      const payload = {
        field_id: this.field.fieldId,
        year: this.field.year,
        crop_id: this.cultivationCrop,
        year_data_locked: !this.field.year_data_locked,
      }
      this.$emit("lock-year-data", payload)
    },
  },
  watch: {
    field: {
      handler() {
        this.$emit("display-save")
      },
      deep: true,
    },
  },
}
</script>
<style scoped>
h2 {
  font-size: 21px;
  font-weight: 500;
}

.action-required {
  font-size: 32px;
  color: #fedb80;
}

.complete {
  font-size: 32px;
  color: #00a200;
}

.failed {
  font-size: 32px;
  color: #cc0101;
}

.delete {
  color: #cc0101;
}

.arva-success {
  background-color: #edf9dc;
}

.eighth-width {
  width: 12.5%;
}

.year-cell {
  min-width: 70px;
}

.crop-cell {
  min-width: 180px;
}

.actions-cell {
  min-width: 60px;
}

.cell-table {
  padding: 0;
}

.cell-padder {
  padding: 2px 6px;
}

.light-blue-bg {
  background-color: #e3f5fc;
}

.test {
  background-color: #0095ff;
  /* background: rgba(107, 153, 206, 1) !important; */
}

.white-bg {
  background-color: white;
}

.gray-bg {
  background-color: #f6f7fb !important;
}

/* crop column */
.crop-column {
  font-size: 18px;
}

.v-input {
  font-size: 18px;
}

/* hide arrows/spin button for number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.action-button {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
