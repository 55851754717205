<template>
  <ul class="nav-left flex-wrap">
    <li>
      <a
        class="side-nav-toggle"
        href="javascript:void(0);"
        @click="$emit('collapse')"
      >
        <i class="ti-view-grid" />
      </a>
    </li>

    <FilterDropdown
      :items="orgNodes"
      :open="getOpen(DROPDOWN.OrgNode)"
      :dropdown-type="DROPDOWN.OrgNode"
      :count-badge-val="selectedOrgNodeCount"
      :display-name="corporationName"
    />

    <FilterDropdown
      :items="crops"
      :open="getOpen(DROPDOWN.Crop)"
      :chunked="crops && crops.length >= 20"
      :count-badge-val="selectedCropsCount"
      :dropdown-type="DROPDOWN.Crop"
      display-name="Crop"
    />

    <FilterDropdown
      :items="clients"
      :open="getOpen(DROPDOWN.Client)"
      :chunked="clients && clients.length >= 20"
      :count-badge-val="selectedClientsCount"
      :dropdown-type="DROPDOWN.Client"
      display-name="Client"
    />

    <FilterDropdown
      :items="farms"
      :open="getOpen(DROPDOWN.Farm)"
      :chunked="farms && farms.length >= 20"
      :count-badge-val="selectedFarmsCount"
      :dropdown-type="DROPDOWN.Farm"
      display-name="Farm"
    />

    <FilterDropdown
      :items="fields"
      :open="getOpen(DROPDOWN.Field)"
      :chunked="fields && fields.length >= 20"
      :count-badge-val="selectedFieldsCount"
      :dropdown-type="DROPDOWN.Field"
      display-name="Field"
    />

    <li>
      <div class="acreage">{{ selectedFieldsAcreage | prettyInteger }} ac.</div>
    </li>
  </ul>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import { DROPDOWN, IS_RANCHFORCE } from "@/constants"
import { Filter } from "@/store/modules"

import FilterDropdown from "@/components/dropdowns/FilterDropdown"
// import NavLink from "./NavLink"

export default {
  name: "TopNav",
  components: {
    FilterDropdown,
  },

  data() {
    return {
      DROPDOWN,
      isRanchForce: IS_RANCHFORCE,
    }
  },

  computed: {
    ...mapGetters({
      getOpen: Filter.Getters.getOpen,
      orgNodes: Filter.Getters.getOrgNodes,
      selectedOrgNodeCount: Filter.Getters.getSelectedOrgNodeCount,
      crops: Filter.Getters.getCrops,
      selectedCropsCount: Filter.Getters.getSelectedCropsCount,
      clients: Filter.Getters.getClients,
      selectedClientsCount: Filter.Getters.getSelectedClientsCount,
      farms: Filter.Getters.getFarms,
      selectedFarmsCount: Filter.Getters.getSelectedFarmsCount,
      fields: Filter.Getters.getFields,
      selectedFieldsCount: Filter.Getters.getSelectedFieldsCount,
      selectedFieldsAcreage: Filter.Getters.getSelectedFieldsAcreage,
    }),

    ...mapState({
      corporationName: state =>
        state.Organization.organization.corporation.name,
    }),
  },
}
</script>

<style scoped>
@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 26.5px;
    padding-bottom: 26.5px;
    line-height: 27px;
  }
}

.acreage {
  background: #666;
  color: white;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 5px;
  margin-top: 21px;
}
</style>
