<template>
  <div>
    <h1 class="evidencing-title">Evidencing</h1>

    <div class="evidencing-view-selector">
      <a class="no-decoration" href="/evidencing/view-group/all/">All</a>
      <a class="selected">Groups</a>

      <div class="evidencing-view-btns">
        <div class="groups-search">
          <v-icon>mdi-magnify</v-icon>
          <input
            type="text"
            placeholder="Group/Client name..."
            v-model="tableData['groupSearch']"
          />
        </div>

        <v-btn v-if="isSuperUser" class="create-group-btn" outlined height="32" href="/evidencing/create-group/">
          <img :src="plusIcon" />
        </v-btn>
      </div>
    </div>

    <div class="evidencing-table-wrapper">
      <div class="fxn-wrapper">
        <h3>{{ sortedFilteredEvidencingGroups.length }} Groups</h3>

        <div class="configuration-btns"></div>
      </div>

      <div class="evidencing-table">
        <v-simple-table>
          <thead>
            <tr>
              <th
                v-for="head, idx in tableData['headers']"
                :key="head + idx"
                :data-header="head.toLowerCase()"
              >
                <a>
                  {{ head }}
                  <div class="evidencing-table-sorters" v-if="idx < tableData['headers'].length-1">
                    <font-awesome-icon :icon="['fas', 'caret-up']" />
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                  </div>
                </a>
              </th>
            </tr>
          </thead>
  
          <tbody>
            <tr
              v-for="e in sortedFilteredEvidencingGroups"
              :key="e['id'] + e['name']"
              @click="viewGroup(e['id'])"
            >
              <td>{{ e['name'] }}</td>
              <td>{{ e['client'] }}</td>
              <td><p>{{ e['crops'] }}</p></td>
              <td class="pre-wrap"><p>{{ e['deadlines'] }}</p></td>
              <td>{{ e['farms'] }}</td>
              <td>{{ e['uploads'] }}</td>
              <td class="progress-cell">
                <v-progress-linear :value="e['submitted']" color="blue-grey" height="24">
                  <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </td>
              <td class="progress-cell">
                <v-chip color="deep-purple accent-4" outlined>
                  Needs Validation
                </v-chip>
              </td>
              <!-- <td>
                <v-btn disabled outlined @click="validateGroup(e['id'])">
                  <v-icon>mdi-eye-check-outline</v-icon>
                </v-btn>
                <v-btn outlined @click="editGroup(e['id'])">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td> -->
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import plusIcon from "@/assets/images/plus-icon.svg";
import { fetchClientBasedGroups, fetchPastureClientBasedGroups } from "@/api/EvidencingAPI";
import { mapState } from "vuex";
import { IS_RANCHFORCE } from "@/constants";

export default {
  name: "EvidencingView",
  components: {},
  data() {
    return {
      tableData: {
        groupSearch: '',
        headers: [
          "Group Name",
          "Client",
          "Crops",
          "Deadlines",
          "Farms",
          "Uploads",
          "Submitted",
          "Status",
          // "Actions"
        ]
      },
      fetchedGroups: [],
      plusIcon,
      isRanchForce: IS_RANCHFORCE,
    }
  },
  computed: {
    ...mapState({
      year: state => state.Organization.year,
      organization: state => state.Organization.organization,
      user: state => state.User.user
    }),
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      
      return false;
    },
    sortedFilteredEvidencingGroups() {
      return this.fetchedGroups.map(({ id, name, uploads, client, farms, crops, due, submitted, status }) => {
        const formattedCrops = crops.join(', ');
        const formattedDueDates = due.join('\n');

        return {
          id,
          name,
          client,
          uploads,
          farms,
          status,
          submitted: submitted * 100,
          crops: formattedCrops,
          deadlines: formattedDueDates,
        }
      })

    }
  },
  methods: {
    getGroupCrops(crops) {
      const cropNames = [];

      for (const crop of crops) {
        if (crop != null && crop['name'] != null) {
          if (!cropNames.includes(crop['name'])) {
            cropNames.push(crop['name'])
          }
        }
        else {
          if (!cropNames.includes('Unspecified Crop')) {
            cropNames.push('Unspecified Crop')
          }
        }
      }

      return cropNames
    },
    viewGroup(id) {
      location.href = `/evidencing/view-group/${id}`;
    },
    editGroup(id) {
      location.href = `/evidencing/edit-group/${id}`;
    }
  },
  watch: {
    organization: {
      handler(curr) {
        if (curr != null && curr['id'] != null) {
          const fetchClientBasedGroupsAPI = this.isRanchForce ? fetchPastureClientBasedGroups : fetchClientBasedGroups;
          fetchClientBasedGroupsAPI({ year: this.year, org_node_id: curr['id'] })
          .then(response => {
            if (response['data'] && response['data'].length > 0) {
              this.fetchedGroups = response['data']
            }
          })
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.evidencing-title {
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
  margin: 0 0 8px;
  color: #000000;
}
.evidencing-view-selector {
  display: flex;
  justify-content: flex-start;
}
.evidencing-view-selector a {
  margin-right: 8px;
}
.evidencing-view-selector a.selected {
  font-weight: bold;
}
.evidencing-table-wrapper {
  background: #FFFFFF;
  padding: 24px 0 0;
  margin-top: 24px;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}
.evidencing-table-wrapper .fxn-wrapper {
  margin-bottom: 24px;
  padding: 0 24px;
}
.evidencing-table-wrapper .fxn-wrapper h3 {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-weight: bold;
}
.evidencing-table {
  max-height: 638px;
  overflow-y: scroll;
}

.groups-search {
  width: 100%;
  max-width: 300px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 4px 12px;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-end;
  margin-left: auto;
  margin-right: 8px;
}
.groups-search i {
  margin-right: 12px;
}
.groups-search input {
  width: 100%;
}
.evidencing-view-btns {
  display: flex;
  margin-left: auto;
}
.create-group-btn {
  width: 32px;
  padding: 0 !important;
  min-width: unset !important;
  opacity: 0.5;
  margin-left: 6px;
  transition: opacity 0.25s;
}
.create-group-btn:hover {
  opacity: 1;
}
</style>

<!-- table styles -->
<style scoped>
.evidencing-table ::v-deep(table) {
  table-layout: fixed;
}
th {
  position: sticky;
  top: 0;
  z-index: 1;
  color: #757575;
  background: #ffffff;
  text-transform: uppercase;
  height: 43px !important;
}
th[data-header="group name"] {
  width: 250px;
}
th[data-header="actions"] {
  width: 200px;
}
th[data-header="crop(s)"] {
  width: 100px;
}
th[data-header="due"] {
  width: 120px;
}
th[data-header="uploads"] {
  width: 105px;
}
th[data-header="client"],
th[data-header="status"] {
  width: 175px;
}
th[data-header="farms"] {
  width: 95px;
}
th a {
  padding: 12px 0;
  font-size: 12px;
  line-height: 20px;
  color: #000000 !important;
  display: flex;
  width: 100%;
}
th a .evidencing-table-sorters {
  margin-left: auto;
  position: relative;
}
th a .evidencing-table-sorters > svg {
  color: #cbd5e0;
}
th a .evidencing-table-sorters > svg.activated-sort {
  color: #687588;
}
th a .evidencing-table-sorters > svg:first-of-type {
  position: absolute;
  top: 1px;
  right: 0;
}
th a .evidencing-table-sorters > svg:last-of-type {
  position: absolute;
  bottom: 1px;
  right: 0;
}
th,
td {
  font-size: 14px;
  line-height: 20px;
}
td * {
  pointer-events: none;
}
tr {
  position: relative;
}
td {
  /* white-space: pre-wrap; */
  vertical-align: top;
  padding: 12px 16px !important;
}
td > button:not(:last-of-type) {
  margin-right: 8px;
}
td p {
  margin: 0;
  color: #000000;
  line-height: 1.5;
  text-transform: capitalize;
}
tbody tr {
  cursor: pointer;
}
.pre-wrap {
  white-space: pre-wrap;
}
.no-decoration {
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.25s;
}
.no-decoration:hover {
  color: #000000;
}
td.progress-cell {
  vertical-align: middle;
}
.v-progress-linear {
  width: calc(100% - 12px) !important;
  border-radius: 6px;
}
.superuser-hoverable-actions {
  position: absolute;
  right: 0;
}
</style>